

























import { Office } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import OfficeCard from './OfficeCard.vue';
import OfficeCarousel from './OfficeCarousel.vue';

@Component({
  components: {
    OfficeCard,
    OfficeCarousel,
  },
})
export default class Offices extends Vue {
    @Prop()
    private readonly offices!: Office[];
}
