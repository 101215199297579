










import { Profile } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Carousel from './Carousel/Carousel.vue';
import ProfileCard from './ProfileCard.vue';

@Component({
  components: {
    ProfileCard,
    Carousel,
  },
})
export default class ProfileCarousel extends Vue {
  @Prop({ required: true })
  private readonly profiles!: Profile[];
}
