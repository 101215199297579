










import { Office } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Carousel from './Carousel/Carousel.vue';
import OfficeCard from './OfficeCard.vue';

@Component({
  components: {
    OfficeCard,
    Carousel,
  },
})
export default class OfficeCarousel extends Vue {
  @Prop({ required: true })
  private readonly offices!: Office[];
}
